<template>
  <div class="chart" ref="el"></div>
</template>
<script>

import {NestedPieChart} from "@toast-ui/chart";
import {onMounted, ref} from "vue";

export default {
  name: "ToastNestedPieChart",
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    series: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => (
          {
            chart: { width: 400, height: 400 },
            series: {
              clockwise: false,
              dataLabels: {
                visible: false,
              },
              first: {
                radiusRange: {
                  inner: '30%',
                  outer: '40%'
                },
              },
              second: {
                radiusRange: {
                  inner: '45%',
                  outer: '55%'
                }
              },
              third: {
                radiusRange: {
                  inner: '60%',
                  outer: '70%'
                }
              }
            },
            legend: {
              visible: false
            },
            tooltip: {
              template: (model) => {
                if(model.data[0].label){
                  return `<div style="background: #FFFFFF;width: 140px;padding: 0 5px;text-align: center;"><div class="toastui-chart-tooltip-series-wrapper" style="font-weight: normal; color:black; font-size: 12px; "><div class="toastui-chart-tooltip-series"><span class="toastui-chart-series-name"><i class="toastui-chart-icon" style="background: ${model.data[0].color}"></i><span class="toastui-chart-name" style="color:black;">${model.data[0].category}</span></span><span class="toastui-chart-series-value">${model.data[0].value}</span></div></div></div>`;
                }
                return '';
              }
            },
            exportMenu: {
              visible: false
            },
            theme: {
              series: {
                first: {
                  colors: ['#84888B', '#e8e8e8'],
                },
                second: {
                  colors: ['#545045', '#e8e8e8'],
                },
                third: {
                  colors: ['#FFBC00', '#e8e8e8'],
                }
              },
            },
            usageStatistics: false
          }
      )
    }
  },
  setup(props){
    const el = ref(null);
    const chart = ref(null);

    onMounted(()=>{
      const data = {
        categories: props.categories,
        series: props.series
      };
      chart.value = new NestedPieChart({el: el.value, data, options: props.options});
    });

    return {
      el,
    }
  }
}
</script>
